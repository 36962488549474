p,
a,
li,
blockquote,
form,span {
    -webkit-font-smoothing: antialiased;
	font-weight: 500;
}

p, ul, ol {
	margin-top: 0;
	margin-bottom: 20px;
	.small {
		font-size: 16px;
		@media only screen and (max-width: $medium) {
			font-size: 14px;
		}
	}
}

a {
	text-decoration: none;
	color: $colour-primary;
	&:hover img.icon {
		margin-left: 10px;
	}
	img.icon {
		transition: all 200ms $ease-bounce;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
	color: #120A42;
}

h6 {
	font-weight: 500;
}


// as determined by design
h1,
.h1 {
	@include font-size(48px);
	line-height: rem-calc(53px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
    h1,
    .h1 {
    	@include font-size(33px);
    	line-height: rem-calc(37px);
    }
}

h2,
.h2 {
	@include font-size(35px);
	line-height: rem-calc(39px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h2,
	.h2 {
		@include font-size(26px);
		line-height: rem-calc(29px);
	}
}

h3,
.h3 {
	@include font-size(30px);
	line-height: rem-calc(33px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h3,
	.h3 {
		@include font-size(23px);
		line-height: rem-calc(25px);
	}
}

h4,
.h4 {
	@include font-size(26px);
	line-height: rem-calc(29px);
	font-family: $header-font-family;
	font-weight: 400;
}

@media screen and (max-width: $medium) {
	h4,
	.h4 {
		@include font-size(20px);
		line-height: rem-calc(22px);
	}
}

h5,
.h5 {
	@include font-size(22px);
	line-height: rem-calc(24px);
	font-family: $header-font-family;
	font-weight: 400;
}

@media screen and (max-width: $medium) {
	h5,
	.h5 {
		@include font-size(22px);
		line-height: rem-calc(20px);
	}
}

h6,
.h6 {
	@include font-size(19px);
	line-height: rem-calc(21px);
	font-family: $header-font-family;
	font-weight: 400;
	color: $colour-primary;
}

@media screen and (max-width: $medium) {
	h6,
	.h6 {
		@include font-size(19px);
		line-height: rem-calc(18px);
	}
}

::selection {
	background-color: $colour-primary;
	color: #fff;
}

.subtitle {
	font-weight: 700;
}

ul.checklist, ol.checklist {
	list-style: none;

	li {
		padding-left: 40px;
		position: relative;

		&::before {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			top: 7px;
			left: 8px;
			background: url('/img/site/check.svg') no-repeat;
            background-size: contain;
		}
	}
}