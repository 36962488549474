.sidebar {
    position: sticky;
    top: 80px;

    @media only screen and (min-width: $medium) {
		padding-top: 40px;
	}

    &__item {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.5rem;
        border-top: 1px solid $grey;

        &__titel {
            flex-grow: 1;
            color: $charcoal;
            font-size: $base-font-size;
            font-weight: 700;
        }

        &__icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            opacity: 0.25;
            transform: translateX(-1rem);
            transition: opacity 200ms ease-in-out, transform 200ms $ease-bounce;
        }

        &:hover {
            .sidebar__item__icon {
                opacity: 1;
                transform: translateX(-0.5rem);
            }
        }
    }
}