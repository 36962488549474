$sizes: (
	0: 0px,
	5: 5px,
	10: 10px,
	15: 15px,
	16: 16px,
	20: 20px,
	30: 30px,
	40: 40px,
	50: 50px,
	60: 60px,
	70: 70px,
	80: 80px,
	90: 90px,
	100: 100px,
	110: 110px,
	120: 120px,
	130: 130px,
	140: 140px,
	150: 150px,
	160: 160px,
	170: 170px,
	180: 180px,
	190: 190px,
	200: 200px,
	gut: 46px,
	halfgut: 23px,
);

// $sides: ('left', 'right', 'top', 'bottom');

$sides: (
	'l': 'left',
	'r': 'right',
	'b': 'bottom',
	't': 'top'
);


$screens: (
	'sm': $small,
	'md': $medium,
	'lg': $large,
	'xl': $xlarge,
	'ms': $fluid,
);

$vhHeights: (
	40,
	45,
	50,
	55,
	60,
	65,
	70,
	75,
	80,
	85,
	90,
	95,
	100
);

$ease-bounce: cubic-bezier(.4, 0, .25, 1.333),
