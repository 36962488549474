.header {
    border-bottom: 1px solid #F2F1FE;
    z-index: 99999999;

    .bg-grey {
        background-color: #F7F7FE;
        z-index: 9999999;
    }

    &__logo {
        text-align: center;
        //left: 64px;
        z-index: 99999999;
        width: 165px;
        a {
            display: block;
        }

        img {
            height: 64px;
            width: 165px;
        }
        @media only screen and (max-width: $large) {
            position: absolute;
            left: 10px;
            top: 0;
            padding: 0;
            z-index: 99;
            width: 103px;
            height: 54px;

            img {
                width: 103px;
                height: 40px;
            }

            a {
                position: absolute;
                left: unset;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &__nav {
        //background-color: $grey-grey-light;
        @media only screen and (max-width: $large) {
            background-color: $grey-grey-light;
            //display: flex;
            //display: -webkit-flex; /* Safari */
            //-webkit-flex-direction: column-reverse; /* Safari 6.1+ */
            //flex-direction: column-reverse;
        }
    }
    &__cta {
        a {
            font-size: 16px;
			color: #575179;
			font-weight: bold;
        }
    }
    &__top {
        height: 55px;
        margin: 0 -15px;
        width: 100vw;

        .whatsapp {
            right: 110px;
            font-size: 14px;
            position: absolute;
            top: 18px;
        }
    }
    &__bottom {
        height: 60px;
        position: relative;

        span {
            font-size: 14px;
            font-weight: bold;
            color: #575179;
        }

        &::after {
            content: "";
            width: 40px;
            height: 54px;
            position: absolute;
            right: -7px;
            top: 0;
            pointer-events: none;
            z-index: 9;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
            display: block;

            @media only screen and (min-width: $small) {
                display: none;
            }
        }
    }
}

.mtHeader {
    @media only screen and (min-width: $large) {
        margin-top: 83.06px;
    }
}

.headerFixed {
    @media only screen and (min-width: $large) {
        position: fixed;
        width: 100%;
    }
}
