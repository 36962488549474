.list-group {
    h6 {
        color: $black;
    }
    a {
        color: $colour-font;
        font-size: 16px;
        display: inline-block;
        width: 100%;
        &:hover {
            img {
                margin-right: 10px;
            }
        }
    }
    img {
        transition: all ease 0.2s;
        float: right;
        margin-right: 20px;
    }
    &--fixed {
        a {
            max-width: 80%;
            &:hover {
                img {
                    right: 5px;
                    margin-right: inherit;
                }
            }
        }
        li {
            position: relative;
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                margin: auto;
             }
        }
    }
}

.matrix,
.info-text {
    ul,
    ol {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    ul li {
        position: relative;
        padding-left: 2em;

        &:before {
            font-size: 32px;
            content: '•';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            color: $purple;
        }
    }

    ol {
        counter-reset: list;

        > li {
            position: relative;
            padding-left: 2em;

            &:before {
                counter-increment: list;
                content: counter(list) '.';
                display: inline-block;
                color: $purple;
                position: absolute;
                left: 0;
            }

            // This selected every adjacent <li>
            // from the 10th and overrides the
            // content property of each
            &:nth-child(10n) ~ li:before,
            &:nth-child(10n):before {
                content: counter(list);
            }
        }
    }

    ul ol,
    ol ul {
        padding-top: 0;
    }
}
