.actieknoppen {
    margin: 0;
    padding: 64px 0;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem 1rem;

    .actieknop {

        &__titel {
            margin-bottom: 1rem;
            color: $colour-primary;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1rem;
        }

        &__link {
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.5rem;
            border-top: 1px solid $grey;
            transition: all 200ms ease-in-out;

            &__titel {
                flex-grow: 1;
                color: $charcoal;
                font-size: $base-font-size;
                font-weight: 700;
            }

            &__icon {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                opacity: 0.25;
                transform: translateX(-1rem);
                transition: transform 200ms $ease-bounce, opacity 200ms ease-in-out;
            }

            &:hover {
                .actieknop__link__icon {
                    opacity: 1;
                    transform: translateX(-0.5rem);
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
