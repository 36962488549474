// Navigation
nav {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding: 0;
        margin: 0;
        display: block;
        &:before {
            content: '';
        }
        font-size: 16px;
    }
}

.nav {
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        padding: 0;
        margin: 0;
        &:before {
            content: '';
        }
    }
    &--main {
        overflow: hidden;
        height: 0;
        min-height: 0;
        text-align: left;
        a {
            text-decoration: none;
            font-size: 16px;
            color: #575179;
            font-weight: 700;
            cursor: pointer;
        }
        li {
            line-height: 2.5;
            &.has-children {
                position: relative;

                @media only screen and (min-width: $large) {
                    padding: 19px 16px 19px 32px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 18px;
                    right: -10px;
                    width: 8px;
                    height: 6px;
                    background-repeat: no-repeat;
                    z-index: 9999;
                }
                ul {
                    display: none;
                    li {
                        border: 0;
                        padding: 0;

                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
            border-bottom: 2px solid rgba(249, 252, 253, 0.12);
        }
        @media only screen and (min-width: $large) {
            background-color: palette(white);
            height: inherit;
        }
    }
}

@media only screen and (max-width: $large) {
    .nav {
        h6 {
            margin-top: 1em;
        }
        ul {
            li a {
                color: $colour-font;
            }
            li.has-children {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 5px;
                    right: 2px;
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat;
                    z-index: 9999;
                    background-image: url('/img/site/plus/color4.svg');
                    background-position: center center;
                }
                &.active {
                    ul {
                        display: block;
                        margin: 0;
                    }
                    &:after {
                        background-image: url('/img/site/min/color3.svg');
                    }
                }
                .nav__level2 li:after {
                    content: inherit !important;
                    background-image: inherit !important;
                }
            }
        }
    }
    .nav__contact--top {
        margin-top: 1em !important;
    }
    .nav__contact--bottom {
        margin-bottom: 1em !important;
    }

    .nav--main {
        margin-left: -15px;
        width: 100vw !important;
    }
}

@media only screen and (min-width: $large) {
    .nav--main {
        overflow: inherit;
    }
    .nav {
        &__contact.none,
        &__contact.none {
            display: none;
        }

        .topbar {
            .socials {

                .header__contact {
                    height: 80px;
                }
                a {
                    color: $white;
                    font-size: 16px;
                }
            }
        }

        .searchbar {
            z-index: 999;
            position: relative;
        }
    }


    ul.nav__holder {
        text-align: left;
        width: 100%;

        @media only screen and (min-width: $fluid) {
            margin: 0;
            margin-left: auto;
        }



        li {
            padding: 19px 0 19px 27px;
            margin: 0;
            text-align: left;
            display: inline;
            position: static;

            @media only screen and (min-width: 1050px) {
                padding: 19px 0 19px 32px;
            }

            &:before {
                content: '';
            }
            &:hover > a {
                position: relative;
            }
            &:hover.has-children {
                ul.nav__level2 {
                    background-color: $grey-grey-light;
                    display: flex !important;
                    position: absolute;
                    z-index: 99;
                    top: 80px;
                    right: -50px;
                    padding: 70px;
                    width: 700px;
                    flex-wrap: wrap;

                    li:hover > a:after {
                        content: none;
                    }
                    > li {
                        display: inline-block;
                        text-align: left;
                        padding: 2px 30px;
                        vertical-align: top;
                        width: 50%;
                        a {
                            color: $grey-dark;
                            white-space: normal;
                        }
                    }
                    ul {
                        display: block;
                        li {
                            display: block;
                        }
                    }
                }
            }
            &.has-children {
                &:after {
                    content: ' ';
                    background-image: url('/img/site/drop-down-icon/down/color3.svg');
                    position: absolute;
                    top: -3px;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }

                .has-children {
                    &::after {
                        display: none;
                    }
                }
            }
            &.has-children ul {
                display: none;
            }
        }
    }
}
.searchbar {
    padding: 0 16px;

    @media only screen and (min-width: $large) {
        padding: 0;
    }

    .searchResults {
        position: static;
        left: 0;
        top: 50px;
        width: 100%;

        @media only screen and (min-width: $large) {
            position: absolute;
        }
    }

    .search-icon {
        height: 100%;
        position: absolute;
        right: 17px;
        padding-top: 10px;
        margin-right: -16px;
        top: 0;

        img {
            padding-right: 17px;
        }
    }
    search-bar {
        width: 100%;
    }
    ais-instant-search {
        margin-top: .75rem;
    }
    .ais-Autocomplete, ais-autocomplete {
        .bg-white {
            @media only screen and (min-width: $fluid) {
                margin: 0;
                margin-left: auto;
            }
        }
        ul {
            margin-left: 0;
            background: #fff;
        }
        ul li {
            line-height: unset;
            border: unset;
        }
        .result-title {
            padding: 11px 20px 7px;
        }
        .results {
            border: 1px solid #EEEBF2;

            li {
                border-bottom: 1px solid #E9E6FE;
                color: #575179;

                span,
                a {
                    color: #575179;
                    font-weight: 700;
                }

                a {
                    padding: 11px 20px 7px;
                    transition: 0.2s ease-in-out;
                    width: 100%;
                    display: flex;

                    &:hover {
                        background: #f6f6f6;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                mark {
                    background-color: #fff;
                    color: #575179;
                    font-weight: bold;
                }
            }
        }

        .searchbarInput {
            border: 1px solid #EFEDFD;
            color: #575179;
            font-weight: bold;
            box-sizing: border-box;
            padding-top: 7px;
            -webkit-appearance: none;
            -webkit-border-radius:2px;
            border-radius: 2px;
            font-size: 16px;
            outline-color: #575179;
        }
        .searchbarInput::placeholder {
            text-align: left;
            vertical-align: center;
			color: #575179;
			font-weight: 400;
            opacity: 1;
        }
        .searchbarInput:-ms-input-placeholder {
            text-align: left;
            vertical-align: center;
        }
    }


}
.topbar {
    .container {
        @media only screen and (max-width: $large) {
            padding-left: 0;
            padding-right: 0;
        }
        ul {
            @media only screen and (max-width: $large) {
                margin-left: 0;
                padding-left: 30px;
            }
        }
    }
    .socials-div {
        @media only screen and (min-width: $xlarge) {
            -ms-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
            max-width: 83.333333%;

        }
        @media only screen and (min-width: $fluid) {
            -ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
        }
    }

    .nav__holder {
        flex-direction: column;

        @media only screen and (min-width: $large) {
            flex-direction: row;
        }
        @media only screen and (max-width: $xlarge) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

        }
    }
}

.nav {
    &__holder {
        top: 0;
        position: relative;
        z-index: 99999;
        display: flex;
        align-items: center;

        @media only screen and (min-width: $large) {
            transform: translateY(0);
        }
    }
    &__item {
        display: table;
        width: 100%;
        white-space: nowrap;
        color: #575179;
        font-weight: 700;
    }
    &__hamburger {
        position: absolute;
        top: 0;
        z-index: 999;
        right: 0;
        width: 64px;
        height: 64px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        display: block;
        @media only screen and (min-width: $large) {
            display: none;
        }
        &:before {
            content: 'Menu';
            position: absolute;
            top: 25px;
            left: -27px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
        }
        &.open {
            &:before {
                content: 'Sluit';
            }
        }
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 20px;
            background-color: #575179;
            opacity: 1;
            right: 25px;
            transform: rotate(0deg);
            transition: .2s ease-in-out;
            &:nth-child(1) {
                top: 28.5px;
            }
            &:nth-child(2) {
                width: 20px;
                top: 34.5px;
            }

        }
        &.open span:nth-child(2) {
            transform: rotate(45deg) translate(-8.3px, 0.3px);
        }
        &.open span:nth-child(1) {
            transform: rotate(-45deg) translate(-4px, -5px);
        }
    }
}

