.faq, .newFaq {
    &__item {
        height: auto;
        border-color: #ddeaf6;
        border-style: dotted;
        border-bottom: 2px dotted #ddeaf6;
        cursor: pointer;
        &.active {
            .faq__question {
                &:after {
                    transform: rotate(90deg);
                }
                &:before {
                    transform: rotate(180deg);
                }
            }
            .faq__answer {
                max-height: 3000px;
                transition: all ease 1s;
            }
        }
    }
    &__question {
        position: relative;
        h5 {
            margin: 0;
            padding: 0;
        }
        &:after {
            position: absolute;
            content: '';
            left: 8px;
            top: 12px;
            background-color: $colour-primary;
            height: 16px;
            width: 2px;
            margin-top: 5px;
            transition: all ease-in-out 0.5s;
        }
        &:before {
            position: absolute;
            content: '';
            background-color: $colour-primary;
            left: 1px;
            top: 19px;
            height: 2px;
            width: 16px;
            margin-top: 5px;
            transition: all ease-in-out 0.6s;
        }
    }
    &__answer {
        padding: 0 24px;
        overflow: hidden;
        max-height: 0;
        transition: all ease 1s;
    }
}
