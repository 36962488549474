html, body {
	height: 100%;
	width: 100%;
	font-size: 16px;
	line-height: $base-line-height;
	
	@media only screen and (min-width: $medium) {
		font-size: $base-font-size;
	}
}

body {
	font-family: $base-font-family;
	color: $colour-font;
	height: auto;
}

// Contain images and iframe to their parent, rarely do we want them escaping their parent.
img, iframe {
	max-width: 100%;
}

// This gets around width and height attributes on images.
img {
	width: 100%;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

.clip {
	pointer-events: none;
	position: absolute;
}

.minMarginTop {
	margin-top: -10px;

	@media only screen and (min-width: 768px) {
		margin-top: -30px;
	}
}
