footer {
    font-size: 16px;
    @media only screen and (max-width: $medium) {
        font-size: 14px;
    }
    a,
    p {
        font-size: 16px;
        color: #5b5b5f;
        line-height: 22px;
        margin-bottom: 5px;
        @media only screen and (max-width: $medium) {
            font-size: 14px;
        }
    }
}

.footer-bg {
    position: relative;
    padding-top: 20px;
    @media only screen and (min-width: $medium) {
        padding-top: 60px;
    }
    &:after {
        @media only screen and (min-width: $medium) {
            content: '';
            z-index: -1;
            background-color: $grey-grey-light;
            position: absolute;
            left: 0;
            width: 50vw;
            top: 0;
            bottom: 0;
        }
    }
}
