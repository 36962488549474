
.card {
    .card__image {
        position: relative;
        display: block;
        overflow: hidden;
        &:hover {
            .link-icon {
                bottom: -40px;
                right: -40px;
            }
        }
    }
}

.home--slider {
    overflow-x: hidden;
    
    .slick-list {
        overflow: visible;
    }
    .slider__item {
        margin-right: 50px;
        outline: 0;
    }
    &:before {
        content: '';
        background-color: #fff;
        width: 100%;
        left: -100%;
        top: 0;
        bottom: 0;
        position: absolute;
        z-index: 99999;
    }
}

.link-icon {
    background-color: $colour-primary;
    width: 100px;
    height: 100px;
    border-radius: 90px;
    position: absolute;
    padding: 20px;
    right: -50px;
    bottom: -50px;
    transition: all ease 0.2s;
}