.video-wrap {
    padding-bottom: 56.25%;
    position: relative;
    text-align: center;

    iframe {
    	width: 100%;
    	height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
    }
}


.video-container {
    position: relative;
    display: flex;

    &:hover {
        cursor: pointer;
    }

    video {
        object-fit: cover;
    }

    &__playpause {
        background-image: url(/img/site/playbutton-icon.svg);
        background-repeat: no-repeat;
        width: 30%;
        height: 30%;
        position:absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 5;

        &:after {
            content: "Bekijk de complete video";
            color: #635efb;
            width: 100%;
            right: calc(-50% - 0px);
            bottom: calc(42% - 0px);
            position: absolute;
            text-align: right;
            z-index: 5;
        }
    }

    .video-container--overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(8, 8, 8, 0.25);
        background-blend-mode: overlay;
        background-size: cover;
        img {
            width: 100%;
            height: auto;
        }
    }
}

