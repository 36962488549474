.hero {
    overflow-x: hidden;
    
    &__text {
        @media only screen and (min-width: 1024px){
            max-width: 500px;
        }
        word-break: break-word;
    }
    &__image {
        min-height: 200px;
        background-size: cover;
        background-position: center center;
        @media only screen and (min-width: 560px) {
            min-height: 350px;
        }
        @media only screen and (min-width: 992px) {
            min-height: 500px;
        }
        @media only screen and (min-width: 1024px) and (min-height: 1024px){
            min-height: 50vh;
        }
        @media only screen and (min-width: 1424px) and (min-height: 1024px){
            min-height: 50vh;
        }
    }
    &__left {
        position: relative;
    }

    .heroImg {
        display: flex;
        position: relative;
        margin-left: -15px;
        width: 100vw;

        img,
        video {
            min-height: 245px;
            height: auto;
            object-fit: cover;
            transform: translate3d(0px, 0px, 0px);

            @media only screen and (min-width: 768px) {
                height: 700px;
            }
        }

        @media only screen and (min-width: 768px) {
            margin-left: 0;
            width: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(255,255,255,0) 50%, #FFFFFF 100%);
            pointer-events: none;
        }
    }

    .heroShape {
        clip-path: url('#heroShape');
        @media only screen and (max-width: 767px) {
            max-height: 320px;
        }
    }
}

.slider-arrows {
    position: absolute;
    bottom: 20px;
    right: 69px;
    @media only screen and (max-width: $medium){
        right: 20px;
    }
    .slider__next {}
    .slider__prev {}
}

.js-scrollDown {
    img {
        transition: all ease 0.2s;
    }
     &:hover img {
         margin-bottom: -10px;
     }
}
