// http://chir.ag/projects/name-that-color/

// Core colours
$white: #ffffff;
$black: #000000;
$charcoal: #414042;
$grey: #F2F1FE;
$grey-light: #f8fbff;
$grey-grey-light: #fbfbfd;
// Greys
$gallery: #eeeeee;

// Theme Colours
$purple: #B1AEFB;
$blue: #645EF8;
$black: #323237;
$white: #fcfbfa;
$grey-dark: #5B5B5F;
$flat-white: #fff;

	// Variables
$colour-primary: $blue;
$colour-accent: $purple;
$colour-secondary: $black;
$colour-fourth: $white;

//Variables darkened
$colour-primary-darkened: darken($colour-primary, 10%);
$colour-accent-darkened: darken($colour-accent, 10%);
$colour-secondary-darkened: darken($colour-secondary, 10%);


$colour-background: $white;
$colour-inner-background: $gallery;

$colour-font: $grey-dark;

$theme-colours: (
	white: $white,
	black: $black,
	flat-white: $flat-white,
	grey: $grey,
	purple-light: $purple,
	purple: $colour-primary,
	grey-grey-light: $grey-grey-light,
	charcoal: $charcoal,
	colour-primary: $colour-primary,
	colour-accent: $colour-accent,
	colour-secondary: $colour-secondary,
	colour-primary-darkened: $colour-primary-darkened,
	colour-accent-darkened: $colour-accent-darkened,
	colour-secondary-darkened: $colour-secondary-darkened,
	colour-background: $colour-background,
	colour-inner-background: $colour-inner-background,
	colour-font: $colour-font,
	gallery: $gallery,
	grey-light: $grey-light,
	grey-dark: $grey-dark,
);

//social site colours
$twitter-blue: #55ACEE;
$facebook-blue: #39619F;
$google-red: #F33F2C;

// @tailwind components;
