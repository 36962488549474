.inschrijven {
  h2 {
    padding-bottom: 10px;
  }
  h5 {
    padding-top: 16px;
    @media only screen and (min-width: $medium) {
      padding-top: 30px;
    }
    padding-bottom: 10px;
  }
  .nav-block {
    background-color: rgba(100,94,248,0.2);
    p {
      color: $blue;
      font-size: 14px;
      line-height: 1;
      @media only screen and (min-width: $medium) {
        font-size: 16px;
      }
    }
  }
  label a {
    border-bottom: 2px solid rgba(100,94,248,0.2);
  }
}
