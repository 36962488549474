.locations {
    &__card {
        font-size: 16px;
        display: none;
        &.active {
            display: block;
        }
        @media only screen and (max-width: $medium) {
            font-size: 14px;
        }
        &:hover {
            .link-icon {
                bottom: -40px;
                right: -40px;
            }
        }
    }
    &__switch {
        select {
            border: 0;
            padding: 0;
            margin: 0;
            height: inherit;
            font-family: $base-font-family;
        }
    }
}
