.table {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    td {
        padding: 11px;
        font-size: 16px;
        @media only screen and (max-width: $medium) {
            font-size: 14px;
        }
    }
    td:last-child {
        text-align: right;
    }
    td:first-child {
        width: 80px;
    }
    &-striped {
        tbody tr:nth-of-type(odd) {
            background-color: $grey-grey-light;
        }
        tbody tr:nth-of-type(even) {
            background-color: #fff;
        }
    }
}
