.praktijk {
    &__text {
        //IE Fix
        z-index: 9;
        max-width: 480px;
    }
    &__embed {
        min-height: 280px;
    }
}
