.aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%; /* default 1:1 */
    display: block;

    &.ratio-16-9 {
        padding-top: 9 / 16 * 100%;
    }

    > :last-child {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
