.zoekpagina {
    [v-cloak] { display: none; }
    .result-title {
        padding: 8px 20px;
        font-size: 16px;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        color: #5B5B5F;
    }

    .ais-Highlight {
        mark {
            background-color: #ffffff;
            color: #5B5B5F;
            font-weight: bold;
        }

    }
}

