.button {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 0;
    font-weight: 700;
    padding: 16px 30px;
    color: $white;
    text-decoration: none;
    border: 0;
    outline: none;
    font-size: 14px;
    &--white {
        color: $grey;
        background-color: $white;
    }
    &--red {
        background-color: $colour-primary;
        color: $white;
    }
    &--bordered {
        color: rgba(100,94,248,0.2);
        border-bottom: 2px solid rgba(100,94,248,0.2);
        font-weight: 400;
        font-size: 16px;
        padding: 0;
        @media only screen and (min-width: $medium) {
            padding: 16px 30px;
        }
    }
    &.active {
        color: #645EF8;
        border-bottom: 2px solid #645EF8;;
    }
    &__new {
        background: linear-gradient(45deg, #E86367 0%, #6564F4 100%);
        font-size: 16px;
        font-weight: 700;
        padding: 16px 70px 16px 24px;
        position: relative;
        border-radius: 10px;
        width: 100%;
        text-align: left;

        @media only screen and (min-width: $medium) {
            padding: 24px 70px 24px 40px;
            font-size: 19px;
            width: auto;
        }

        &::after {
            content: "";
            background: url('/img/site/pijltje/new/pijltje-wit-rechts.svg') no-repeat;
            background-size: contain;
            width: 14px;
            height: 7px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 27px;
            margin: auto;
            transition: all 200ms $ease-bounce;
        }

        &--down {
            padding: 19px 47px 15px 24px;

            @media only screen and (min-width: $medium) {
                padding: 24px 70px 24px 40px;
            }

            &::after {
                background: url('/img/site/pijltje/new/pijltje-wit-beneden.svg') no-repeat;
                width: 7px;
                height: 14px;
            }
        }

        &:hover {
            &::after {
                right: 14px;
            }

            &.button__new--down {
                &::after {
                    top: 10px;
                    right: 27px;
                }
            }
        }
    }
}
