.test {
    color: $colour-primary;
}

.news {
    &__inner {
        position: relative;
        padding-right: 64px;
        &:after {
            content: ' ';
            background-color: $colour-primary;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 64px;
            transition: all ease 0.4s;
            z-index: -1;
        }
        h6 {
            transition: 0;
        }
        &:hover {
            h6 {
                color: #fff;
                transition: all ease 0.2s 0.2s;
            }
            &:after {
                transition: all ease 0.2s;
                width: 75%;
            }
            svg path {
                fill: #fff;
            }
        }
    }
}