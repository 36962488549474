.locations {
    margin-top: 40px;
    display: grid;
    grid-template: auto auto auto / 1fr;
    gap: 1rem;

    &__intro {
        grid-row: span 1;
        grid-column: span 1;
    }

    &__locations {
        grid-row: span 1;
        grid-column: span 1;
    }

    &__map {
        grid-row: span 1;
        grid-column: span 1;

        #map {
            width: 100%;
            min-height: 320px;
            height: 100%;

            .mapboxgl-popup-close-button {
                display: none;
            }
        }
    }

    @media only screen and (min-width: $medium) {
        margin-top: 80px;
    }

    @media only screen and (min-width: $large) {
        margin-top: 120px;
        grid-template: auto 1fr / 1fr 1fr 1fr;
    
        &__locations {
            grid-row-start: 2;
            grid-row: span 1;
            grid-column: span 1;
        }
    
        &__map {
            grid-row: span 2;
            grid-column: span 2;
        }
    }
}

.vestigingen {
    min-height: 560px;
    position: relative;

    &__grid {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem 1rem;

        @media only screen and (min-width: $medium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media only screen and (min-width: $large) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .vestiging .heading .toggler {
            display: none;
        }
    }
}
    
.vestiging {
    margin-top: 8px;
    position: relative;
    overflow: hidden;

    .vestiging__content {
        display: none;
    }

    &.active {
        .vestiging__heading {
            color: white;
            background-color: $colour-primary;

            .toggler {
                span {
                    background-color: white;

                    &:first-child { transform: translate(-50%, -50%) rotate(0deg) }
                }
            }
        }

        .vestiging__content {
            padding: 16px 24px 24px;
            display: block;
            background-color: $grey-light;
        }
    }

    &.vestiging__interactive {
        .vestiging__heading {
            cursor: pointer;
        }
        .toggler {
            display: block;
        }
    }

    &:hover {
        .link-icon {
            bottom: -40px;
            right: -40px;
        }
    }
}

.vestiging__heading {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    color: $colour-primary;
    line-height: 1.5;
    background-color: $grey;

    .toggler {
        display: none;
        position: relative;

        span {
            width: 12px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $colour-primary;
            transition: transform 200ms $ease-bounce;

            &:first-child { transform: translate(-50%, -50%) rotate(90deg) }
            &:last-child  { transform: translate(-50%, -50%) rotate(0deg) }
        }
    }
}

.vestiging__content {
    display: block;
    color: $charcoal;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;

    .content__item {
        a {
            margin: 0;
            color: $colour-primary;
            font-size: $base-font-size;
            font-weight: 500;
            line-height: $base-line-height;
        }
    }
}