input[type="text"],
input[type="email"],
input[type="date"],
textarea,
select {
    border: 2px solid rgba(100,94,248,0.2);
    height: inherit;
    background-color: transparent;
    font-family: 'Quicksand';
    padding: 10px;
    margin-bottom: 16px;
    @media only screen and (min-width: 767px) {
        margin-bottom: 20px;
        padding: 15px;
    }
    &:focus {
        border: 2px solid #645EF8;
        outline: 0;
    }
}

select, input {
    height: 50px;
}

hr {
    height: 2px;
    background-color: rgba(100,94,248,0.2);
    margin-bottom: 1em;
}

textarea {
    line-height: 25px;
}

label {
    padding-bottom: 0;
    @media only screen and (min-width: 767px) {
      padding-bottom: 10px;
    }
    display: block;
    font-weight: 300;
    color: #5B5B5F;
    font-size: 16px !important;
    line-height: 26px;
}

textarea {
    resize: none;
    width: 100%;
    padding-left: 10px;
}

input[type="checkbox"] {
    display: inline;
    vertical-align: middle;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
    width: auto;
}

button[type="submit"],
input[type="submit"] {
    background-color: $colour-primary;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
}

select {
    &.disabled {
        opacity: 0.3;
    }
}

select::-ms-expand {
    display: none;
}

.error {
    color: red;
}

/* Customize the label (the container) */
.containerInput {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 16px;
    @media only screen and (min-width: 767px) {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.containerInput input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 2px solid rgba(100,94,248,0.2);
    transition: all ease 0.2s;
}

/* On mouse-over, add a grey background color */
.containerInput:hover input ~ .checkmark {
    border: 2px solid rgba(100,94,248,0.5);
}

/* When the radio button is checked, add a blue background */
.containerInput input:checked ~ .checkmark {
    // background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerInput input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.containerInput .checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    background: #645EF8;
}
